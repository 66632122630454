@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');

body {
    font-family: 'Arvo', serif;
}

.weather-container {
    display: flex;
    justify-content: center;
    padding: 0;
}

.weather-card {
    width: 20rem;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.weather-card__header {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 0.5rem;
}

.weather-card__body {
    padding: 0.5rem;
}

.weather-alert {
    background-color: #ffcc00;
    color: black;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.weather-location {
    font-size: 24px;
}

.weather-date {
    font-size: 12px;
}

.weather-icon img {
    max-width: 50%;
}

.weather-temp {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(244, 208, 63, 0.2);
    box-shadow: 5px 6px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    font-size: 48px;
    font-weight: 900;
    text-shadow: 3px 6px rgba(0, 0, 0, 0.2);
    margin: 0.5rem 0;
}

.weather-temp__number {
    margin-right: 0.5rem;
}

.weather-temp__unit {
    font-size: 1.2rem;
}

.weather-description {
    font-size: 24px;
}

.weather-range {
    font-size: 16px;
}

.weather-card__footer {
    background-color: white;
    padding: 0.5rem;
}

.input-group .form-control {
    background-color: #f8f9fa;
    font-size: 0.8rem;
}

.input-group-append .btn {
    border-color: #ffc107;
    color: black;
    font-size: 0.8rem;
}